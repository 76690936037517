import Medusa from '@medusajs/medusa-js';
import MsJSClient from '@wolfejs/ms-api-js';
import type { InternalAxiosRequestConfig } from 'axios';
import _axios from 'axios';

if (!process.env.MS_API_URL) {
  console.error('MS_API_URL is not set as an environment variable');
}

export const msJsClient = new MsJSClient(
  {
    baseURL: process.env.MS_API_URL || 'http://localhost:8060/api/v1',
    withCredentials: true,
  },
  {
    systemPrefix: process.env.SYSTEM_PREFIX,
    projectPrefix: process.env.PROJECT_PREFIX,
    portalAuthInterceptor: true,
  }
);

export const msClients = {
  msJsClient: msJsClient,
};

export const msJsSSRClient = new MsJSClient(
  {
    baseURL: process.env.MS_API_URL,
    withCredentials: true,
    // only wait 10 seconds to timeout a request, if the service is not responding by then we should show an error to the user
    timeout: 10000,
  },
  {
    portalAuthInterceptor: true,
    refreshTokenInterceptor: false,
    errorInterceptor: true,
    logInterceptor: true,
    systemPrefix: process.env.SYSTEM_PREFIX,
    projectPrefix: process.env.PROJECT_PREFIX,
  }
);

export const medusaAnonClient = new Medusa({
  baseUrl: process.env.MEDUSA_BACKEND_URL || 'https://localhost:8100',
  maxRetries: 3,
});

export const medusaAdminClient = new Medusa({
  baseUrl: process.env.MEDUSA_BACKEND_URL || 'https://localhost:8100',
  maxRetries: 3,
  apiKey: process.env.MEDUSA_API_ADMIN_TOKEN,
});

export const medusaClients: Record<string, Medusa | null> = {
  anonClient: medusaAnonClient,
  adminClient: medusaAdminClient,
  customerClient: null,
};

export const isServer = typeof window === 'undefined';

export const buyerJsClient = _axios.create({
  baseURL: process.env.BUYER_API_URL || 'http://localhost:8064/partner/v1',
  withCredentials: false,
});

buyerJsClient.interceptors.request.use(authInterceptor);

/**
 * Adds a `x-auth-token` header with a token to requests.
 */
export async function authInterceptor<T = unknown>(config: InternalAxiosRequestConfig<T>) {
  const token = process.env.BUYER_API_TOKEN;

  if (token) {
    config.headers['x-auth-token'] = token;
  }
  return config;
}
