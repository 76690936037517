import type MsJSClient from '@wolfejs/ms-api-js/src';
import type { AxiosRequestConfig } from 'axios';

/**
 * Use to get all products
 */
export async function getProducts(
  client: MsJSClient,
  options?: AxiosRequestConfig
) {
  const { data } = await client.products.getProducts(options);
  return data;
}


/**
 * Use to get a product by ID
 */
export async function getProductById(client: MsJSClient, productId: string) {
  const { data } = await client.products.getProduct(productId);
  return data;
}
