'use client';

import { routes } from '@/routes';
import { useAppStore } from '@/stores/appStore';
import { isDevelopment } from '@wolfejs/core/utils/environment';
import type { UserSigninPayload } from '@wolfejs/ms-api-js/src/types/auth';

import { CookieExpiresMax, CookiesKeys } from '@/constants';
import { AppIds } from '@wolfejs/ms-api-js/src/constants/app-ids';
import { usePortalBySlug, useSignin } from '@wolfejs/ms-api-react/src';
import { ApiError } from '@wolfejs/ms-api-react/src/components/ApiError';
import { HeroLogin } from '@wolfejs/ms-ui/HeroLogin';
import { Spinner } from '@wolfejs/ui/Spinner';
import Cookies from 'js-cookie';
import { useParams, useRouter } from 'next/navigation';
import React from 'react';
import { localstore } from '../../../../utils/storage';

export const SigninContainer = () => {
  const router = useRouter();
  const signin = useSignin();
  const { portalSlug: slug } = useParams();

  const user = useAppStore(state => state.user);
  const setUser = useAppStore(state => state.setUser);

  const { data: portal, error: errorFetchingPortal, isLoading: isLoadingPortal } = usePortalBySlug(`${slug}`);

  React.useEffect(() => {
    if (user) {
      if (slug !== 'admin') {
        router.push(`/${slug}${routes.home}`);
      }
    }
  }, [router, user, slug]);

  const handleSubmit = async (signinPayload: UserSigninPayload) => {
    // store the id for this portal by slug in the cookies and localstorage
    if (localstore) localstore.set(`portal-${slug}-id`, portal?.id || '');
    Cookies.set(`portal-${slug}-id`, portal?.id || '', {
      expires: CookieExpiresMax,
    });

    signin.mutate(
      {
        email: signinPayload.email,
        password: signinPayload.password,
        appId: AppIds.PORTALS,
        appSubId: portal?.id,
      },
      {
        onSuccess: response => {
          setUser(response.data);

          const cookieOptions: Cookies.CookieAttributes = {
            path: '/',
          };

          if (!isDevelopment) {
            cookieOptions.sameSite = 'None';
            cookieOptions.secure = true;
          }

          const userCookieOptions = {
            expires: CookieExpiresMax,
          };

          // if (userBusinessMapping?.[0].businessId) {
          //   Cookies.set('user', userBusinessMapping?.[0].businessId);
          // }

          const tryRedirect = () => {
            // console.info('REDIRECTING TO:', `/${slug}${routes.signinRedirect}`);
            let redirectUrl;
            if (!response.data.emailVerified) {
              redirectUrl =
                window.location.protocol + '//' + window.location.host + '/' + slug + '/' + routes.emailVerification;
            } else if (response.data.isAdminMerchant) {
              // Redirect to merchant admin if needed
              console.info('PORTALS SIGNIN PAGE REDIRECTING TO MERCHANT ADMIN:', `/${slug}${routes.signinRedirect}`);
              redirectUrl = window.location.protocol + '//' + window.location.host + '/admin';
            } else {
              console.info('PORTALS SIGNIN PAGE REDIRECTING TO SIGNIN:', `/${slug}${routes.signinRedirect}`);
              redirectUrl =
                window.location.protocol + '//' + window.location.host + '/' + slug + '/' + routes.signinRedirect;
            }

            // redirect using the browser api
            window.location.href = redirectUrl;

            // router.push(`/${slug}${routes.signinRedirect}`);

            // setTimeout(() => {
            //   // console.info('REFRESHING PAGE');
            //   // refresh the page if we still did not redirect after 3 seconds
            //   // router.refresh();
            //
            //   // redirect using the browser api
            //   window.location.href = redirectUrl;
            // }, 3000);
          };

          const redirectToAdmin = () => {
            const redirectUrl =
              process.env.NEXT_PUBLIC_MERCHANT_ADMIN_APP_DEST + routes.admin + '?incomingMerchantAdmin=true';
            window.location.replace(redirectUrl);
            return;
          };

          // store logged in user details in a cookie so SSR has access to it
          if (response.data.appId === AppIds.PORTALS) {
            Cookies.set(CookiesKeys.user, JSON.stringify(response.data), userCookieOptions);
            tryRedirect();
          } else {
            redirectToAdmin();
          }
        },
      }
    );
  };

  if (errorFetchingPortal) return <ApiError error={errorFetchingPortal} />;
  if (isLoadingPortal)
    return (
      <div className="flex h-[65vh] items-center justify-center">
        <Spinner color="var(--primary)" size={50} />
      </div>
    );

  return (
    <HeroLogin
      // Hardcoded title for now until we have admin dashboard
      title={'Insights into your Gift Card Business'}
      subTitle={portal?.subTitle ?? ''}
      onForgotPasswordClick={() => {
        router.push(`/${slug}${routes.forgotPassword}`);
      }}
      error={signin.error}
      onSubmit={handleSubmit}
      isLoading={signin.isLoading}
    />
  );
};
