import type { AxiosRequestConfig } from 'axios';

import type { MsApiResponse } from '../types/api';
import { PgProductEntity, PgSingleDataSource } from '../types/products';
import BaseResource from './base';
import { MsApiEndPoints } from './endPoints';
import { PgPagedDataSource } from '../types/products';

export class ProductsResource extends BaseResource {
  /**
   * Retrieves pgcorp products.
   */
  async getProducts(options?: AxiosRequestConfig) {
    return await this.client.get<PgProductEntity, MsApiResponse<PgPagedDataSource<PgProductEntity>>>(
      MsApiEndPoints.pgcorp.listBrands(),
      options
    );
  }

  /**
   * Retrieves pgcorp product details by id.
   */
  async getProduct(id: string, options?: AxiosRequestConfig) {
    return await this.client.get(MsApiEndPoints.pgcorp.getBrandInfo(id), options);
  }
}
