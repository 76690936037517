import { useQuery } from '@tanstack/react-query';
import { useMsClient } from '../../..';

import { MsApiEndPoints } from '@wolfejs/ms-api-js/src';
import type { PgProductEntity, PgPagedDataSource } from '@wolfejs/ms-api-js/src/types/products';
import type { ReactQueryOptions } from '../../types';
import { getProductById, getProducts } from './api';
import { createPathWithSearchParams } from '@wolfejs/ms-api-js/src/utils/createPathWithSearchParams';

export function useProducts(
  pageNumber: number,
  pageSize: number,
  options?: ReactQueryOptions<PgPagedDataSource<PgProductEntity>>
) {
  const { client } = useMsClient();

  const paginationDetails = {
    pageNumber: pageNumber,
    pageSize: pageSize
  }

  const key = createPathWithSearchParams(MsApiEndPoints.pgcorp.listBrands(), paginationDetails);

  return useQuery({
    ...options,
    queryKey: [key],
    queryFn:() => getProducts(client, {
        params: {
          pageNumber,
          pageSize,
        },
      }), // Extract the data field
  });
}

export function useProduct(id: string) {
  const { client } = useMsClient();

  const key = MsApiEndPoints.pgcorp.getBrandInfo(id);
  return useQuery({
    queryKey: [key],
    queryFn: () => getProductById(client, id),
  });
}
