'use client';

import type { PortalEntity } from '@wolfejs/ms-api-js/src/types/portals';
import React, { createContext } from 'react';

export type AppContextProps = {
  pathname?: string;
  shouldLogout?: boolean;
  accessToken?: string;
  setAccessToken?: (token?: string) => void;
  portal?: PortalEntity;
};

export const AppContext = createContext<AppContextProps>({});

export type AppContextProviderProps = {
  children: React.ReactNode;
  shouldLogout?: boolean;
  pathname?: string;
  accessToken?: string;
  portal?: PortalEntity;
};

export const AppContextProvider = ({
  accessToken: _accessToken,
  shouldLogout,
  portal,
  pathname,
  children,
}: AppContextProviderProps) => {
  const [accessToken, setAccessToken] = React.useState<string | undefined>(_accessToken);
  return (
    <AppContext.Provider value={{ accessToken, setAccessToken, portal, pathname, shouldLogout }}>
      {children}
    </AppContext.Provider>
  );
};
