export enum routes {
  home = '/',
  dashboard = '/dashboard',
  inventory = '/inventory',
  funds = '/funds',
  help = '/help',
  orders = '/orders',
  profile = '/profile',
  signin = '/signin',
  signinRedirect = routes.home, // user will be sent here after signing in
  signup = '/signup',
  signupRedirect = routes.signin,
  emailVerification = '/email-verification',
  forgotPassword = '/forgot-password',
  resetPassword = '/reset-password',
  userAccount = '/myaccount',
  admin = '/admin',
  reports = '/reports',
  shoppingCart = '/shopping-cart',
  checkout = '/checkout',
  confirmation = '/confirmation',
  products = '/products',
}
