import { EmailTemplateResource } from './admin/emailTemplates';
import { AuthResource } from './auth';
import BaseResource from './base';
import { BuyerResource } from './buyers';
import { MerchantResource } from './merchants';
import { MyAccountResource } from './myaccount';
import { OrdersResource } from './orders';
import { PortalResource } from './portals';

export * from './admin';
export * from './endPoints';

export class MSApiPublic extends BaseResource {
  public auth = new AuthResource(this.client);
  public myaccount = new MyAccountResource(this.client);
  public buyers = new BuyerResource(this.client);
  public merchants = new MerchantResource(this.client);
  public portals = new PortalResource(this.client);
  public emailTemplates = new EmailTemplateResource(this.client);
  public orders = new OrdersResource(this.client);
}
