import { useMutation, useQueryClient, type UseMutationOptions } from '@tanstack/react-query';
import type { PgOrderEntity } from '@wolfejs/ms-api-js/src/types/orders';
import type { PgSingleDataSource } from '@wolfejs/ms-api-js/src/types/pgcorp';
import { useMsClient } from '@wolfejs/ms-api-react/src/contexts';
import { buildOptions } from '@wolfejs/ms-api-react/src/utils/buildOptions';
import type { PGCorpCreateOrderPayload } from '@wolfejs/perfectgift-api-js/src';
import { placeOrder } from './api';
import { PgOrderResponse } from '@wolfejs/ms-api-js/src/types/orders';

export const usePGCorpPlaceOrder = (
  options?: UseMutationOptions<PgSingleDataSource<PgOrderResponse>, Error, PGCorpCreateOrderPayload>
) => {
  const { client } = useMsClient();
  const queryClient = useQueryClient();
  const key = 'placeOrder';

  return useMutation<PgSingleDataSource<PgOrderResponse>, Error, PGCorpCreateOrderPayload>(
    (payload: PGCorpCreateOrderPayload) => placeOrder(client, payload),
    buildOptions(queryClient, [key], options)
  );
};
