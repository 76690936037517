import type { AxiosInstance, CreateAxiosDefaults } from 'axios';
import { Admin, MerchantsResource } from './api/admin';
import { EmailAssetsResource } from './api/admin/emailAssets';
import { EmailTemplateResource } from './api/admin/emailTemplates';
import { AuthResource } from './api/auth';
import { BuyerResource } from './api/buyers';
import { MedusaPgcCorpShippingOptionsMappingResource } from './api/medusaPgcCorpShippingOptionsMapping';
import { MyAccountResource } from './api/myaccount';
import { OrdersResource } from './api/orders';
import { PortalResource } from './api/portals';
import { ProductsResource } from './api/products';
import type { MsHttpClientOptions } from './client';
import { createMsHttpClient } from './client';

export * from './api';

export default class MsJSClient {
  // An instance of an axios to use to make requests
  public client: AxiosInstance;

  // admin functions
  public admin: Admin;

  public auth: AuthResource;
  public myaccount: MyAccountResource;

  public buyer: BuyerResource;
  public medusaPgcCorpShippingOptionsMapping: MedusaPgcCorpShippingOptionsMappingResource;
  public merchant: MerchantsResource;
  public portal: PortalResource;
  public emailTemplates: EmailTemplateResource;
  public emailAssets: EmailAssetsResource;
  public orders: OrdersResource;
  public products: ProductsResource;

  constructor(config: CreateAxiosDefaults = {}, options?: MsHttpClientOptions) {
    this.client = createMsHttpClient(config, options);

    this.admin = new Admin(this.client);

    this.auth = new AuthResource(this.client);
    this.myaccount = new MyAccountResource(this.client);

    this.buyer = new BuyerResource(this.client);
    this.medusaPgcCorpShippingOptionsMapping = new MedusaPgcCorpShippingOptionsMappingResource(this.client);
    this.merchant = new MerchantsResource(this.client);
    this.portal = new PortalResource(this.client);

    this.emailTemplates = new EmailTemplateResource(this.client);
    this.emailAssets = new EmailAssetsResource(this.client);
    this.orders = new OrdersResource(this.client);
    this.products = new ProductsResource(this.client);
  }
}
